import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.decorator';
import { IControlComponent } from 'src/app/shared/interfaces/control-component';
import { FormComponentData } from 'src/app/shared/models/people/form-control.model';

@Component({
  selector: 'app-health-allies',
  templateUrl: './health-allies.component.html',
  styleUrls: ['./health-allies.component.css'],
  encapsulation: ViewEncapsulation.None
})
@ComponentLookup('HealthAlliesComponent')
export class HealthAlliesComponent implements OnInit, IControlComponent {

  healthAllies = [
    {
      "id": 3195,
      "logo": "assets/images/logos/assa.png",
      "title": "shared.healthAllies.text4",
      "subTitle": "shared.healthAllies.text9"
    },
    {
      "id": 20142,
      "logo": "assets/images/logos/sura.png",
      "title": "shared.healthAllies.text3",
      "subTitle": "shared.healthAllies.text9"
    },
    {
      "id": 3205,
      "logo": "assets/images/logos/mapfre.png",
      "title": "shared.healthAllies.text2",
      "subTitle": "shared.healthAllies.text9"
    },
    {
      "id": 61090,
      "logo": "assets/images/logos/CMC.png",
      "title": "shared.healthAllies.text13",
      "subTitle": "shared.healthAllies.text11"
    },
    {
      "id": 61092,
      "logo": "assets/images/logos/HChiriqui.png",
      "title": "shared.healthAllies.text14",
      "subTitle": "shared.healthAllies.text12"
    },
    {
      "id": 61848,
      "logo": "assets/images/logos/HPaitilla.png",
      "title": "shared.healthAllies.text15",
      "subTitle": "shared.healthAllies.text12"
    },
    {
      "id": 61096,
      "logo": "assets/images/logos/HSanFernando.png",
      "title": "shared.healthAllies.text6",
      "subTitle": "shared.healthAllies.text12"
    },
    {
      "id": 61093,
      "logo": "assets/images/logos/HSantaFe.png",
      "title": "shared.healthAllies.text8",
      "subTitle": "shared.healthAllies.text12"
    },
    {
      "id": 61097,
      "logo": "assets/images/logos/PacificaSalud.png",
      "title": "shared.healthAllies.text16",
      "subTitle": "shared.healthAllies.text11"
    },
    {
      "id": 61095,
      "logo": "assets/images/logos/panamaClinic.png",
      "title": "shared.healthAllies.text7",
      "subTitle": "shared.healthAllies.text12"
    },
    {
      "id": 61659,
      "logo": "assets/images/logos/townCenter.png",
      "title": "shared.healthAllies.text5",
      "subTitle": "shared.healthAllies.text10"
    }
  ];

  data: FormComponentData;

  constructor(
    private router: Router,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit(): void {

  }
  
  navigateToSearch(clinicalConceptId: number) {    
    this.router.navigate(["/doctores/todos"], {queryParams: {filtros: clinicalConceptId}});
  }  
}